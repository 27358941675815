import Logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
function NavBar() {
  const navigate = useNavigate();

  return (
    <nav class="nxl-navigation">
      <div class="navbar-wrapper">
        <div class="m-header">
          <img
            src={Logo}
            alt="logo"
            style={{ height: "5vh", cursor: "pointer" }}
            onClick={() => {
              navigate("/Leads");
            }}
          />
        </div>
        <div class="navbar-content">
          <ul class="nxl-navbar">
            <li class="nxl-item nxl-caption">
              <label>Navigation</label>
            </li>
            <li class="nxl-item nxl-hasmenu">
              <a class="nxl-link">
                <span class="nxl-micon">
                  <i class="feather-airplay"></i>
                </span>
                <span class="nxl-mtext">Dashboards</span>
                <span class="nxl-arrow">
                  <i class="feather-chevron-right"></i>
                </span>
              </a>
              <ul class="nxl-submenu">
                <li
                  style={{ cursor: "pointer" }}
                  class="nxl-item"
                  onClick={() => {
                    navigate("/compose");
                  }}
                >
                  <a class="nxl-link">Compose</a>
                </li>
                <li class="nxl-item" style={{ cursor: "pointer" }}>
                  <a
                    class="nxl-link"
                    onClick={() => {
                      navigate("/leads");
                    }}
                  >
                    Leads
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default NavBar;
