import { useEffect, useState } from "react";
import "./compose.css";
import TextEditor from "./quilltext";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { Bars } from "react-loader-spinner";

function ComposePannel(props) {
  const navigate = useNavigate();
  const killImportPannel = props.killImportPannel;
  const setRecipientList = props.setRecipientList;
  const [error, setError] = useState(false);
  const [loading, setLoadind] = useState(false);

  const setInitialPrompt = props.setInitialPrompt;
  const [aiPrompt, setAiPrompt] = useState("");
  const [tags, setTags] = useState([]);

  const [menuDisplayed, setMenuDisplayed] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    const token = get_token();
    get_tags(token);
  }, []);

  function get_tags(token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    axios
      .get(process.env.REACT_APP_API_URL + "UniqueTags", { headers })
      .then((response) => {
        if (response.status === 200) {
          setTags(response.data.unique_tags);
        }
      })
      .catch((error) => {
        setError("An Error Occured");
      });
  }

  function capitalizeFirstLetter(str) {
    if (str.length === 0) return str; // Check for an empty string

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  function get_token() {
    var token = localStorage.getItem("orkit_token");
    if (!token) {
      navigate("/Login");
      return;
    }
    token = token.slice(1, -1);
    if (!token) {
      navigate("/Login");
      return;
    }
    return token;
  }

  function emailGeneration() {
    setLoadind(true);
    const token = get_token();
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
    const body = {
      tags: selectedTags,
      search_query: "",
    };

    axios
      .post(process.env.REACT_APP_API_URL + "leads", body, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length == 0) {
            setError("No Recipients Match This Tag");
            return;
          }
          setRecipientList(response.data.leads);
          setInitialPrompt(aiPrompt);
          setLoadind(false);
        }
      })
      .catch((error) => {
        setError("Error Occured, Please try again later");
        setLoadind(false);
      });
  }

  return (
    <div className="composePannelWrapp">
      <section class="step-body mt-4">
        <div
          class="modal-header"
          onClick={() => {
            setMenuDisplayed(false);
          }}
        >
          <h2 class="d-flex flex-column mb-0">
            <span class="fs-18 fw-bold mb-1">Compose Mail</span>
            <small class="d-block fs-11 fw-normal text-muted">
              Compose Your Message
            </small>
          </h2>
          <a
            class="avatar-text avatar-md bg-soft-danger close-icon"
            onClick={killImportPannel}
          >
            <i class="feather-x text-danger"></i>
          </a>
        </div>
      </section>
      {error ? (
        <div class="alert alert-danger" role="alert">
          {" "}
          An Error Happened, Please Try Again Later
        </div>
      ) : (
        ""
      )}

      <tags
        class="tagify form-control border-0 text-dark m-3"
        tabindex="-1"
        aria-expanded="false"
        style={{ zIndex: "10000" }}
      >
        {selectedTags.map((tag, i) => {
          return (
            <tag
              contenteditable="false"
              spellcheck="false"
              tabindex="-1"
              class="tagify__tag "
            >
              <x
                title=""
                class="tagify__tag__removeBtn"
                role="button"
                aria-label="remove tag"
                onClick={() => {
                  const newArray = [...selectedTags];
                  newArray.splice(i, 1);
                  setSelectedTags(newArray);
                }}
              ></x>
              <div>
                <span class="tagify__tag-text">
                  {capitalizeFirstLetter(tag)}
                </span>
              </div>
            </tag>
          );
        })}
        <span
          tabindex="0"
          data-placeholder="Tag"
          aria-placeholder="Tag"
          role="textbox"
          aria-autocomplete="both"
          aria-multiline="false"
        >
          <input
            class="form-control border-0 text-dark"
            placeholder="Title"
            onClick={() => {
              setMenuDisplayed(true);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (selectedTags.includes(e.target.value.toLowerCase())) {
                  return;
                }
                setSelectedTags([
                  ...selectedTags,
                  e.target.value.toLowerCase(),
                ]);
                setMenuDisplayed(false);
                e.target.value = "";
              }
            }}
          />
        </span>
        ​
      </tags>

      {menuDisplayed && (
        <div
          class="border-bottom mail-cc-bcc-fields"
          id="ccbccToggleModalFileds"
        >
          <div
            class="tagify__dropdown users-list"
            role="listbox"
            aria-labelledby="dropdown"
            placement="bottom"
            position="all"
            display="none"
            id="dropdownList"
            style={{ width: "100%", top: "303px;" }}
          >
            <div
              data-selector="tagify-dropdown-wrapper"
              class="tagify__dropdown__wrapper"
            >
              {tags.map((t, i) => {
                return (
                  <div
                    class="tagify__dropdown__item "
                    tabindex="0"
                    role="option"
                    tagifysuggestionidx="1"
                    style={{ zIndex: "10000" }}
                    onClick={() => {
                      if (selectedTags.includes(t.toLowerCase())) {
                        return;
                      }
                      setSelectedTags([...selectedTags, t.toLowerCase()]);
                      setMenuDisplayed(false);
                    }}
                  >
                    <div class="tagify__dropdown__item__avatar-wrap"> </div>{" "}
                    <strong>{t}</strong>
                  </div>
                );
              })}
              <footer
                data-selector="tagify-suggestions-footer"
                class="tagify__dropdown__footer"
              >
                {" "}
                10 more items. Refine your search.{" "}
              </footer>{" "}
            </div>
          </div>
        </div>
      )}
      <div
        class="px-3 w-100 d-flex align-items-center"
        onClick={() => {
          setMenuDisplayed(false);
        }}
      >
        <textarea
          class="form-control border-0 my-1 w-100 h-30 shadow-none"
          type="email"
          placeholder="AI Prompt"
          style={{ height: "10vh" }}
          onChange={(e) => {
            setAiPrompt(e.target.value);
          }}
        />
      </div>

      <div class="container mt-3 mb-0">
        <div class="cols justify-content-center ">
          <div class="col-auto">
            {loading ? (
              <button className="btn btn-primary ms-auto">
                <Bars
                  height="20"
                  width="40"
                  color="white"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />{" "}
              </button>
            ) : (
              <button
                className="btn btn-primary ms-auto"
                onClick={emailGeneration}
              >
                Generate
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComposePannel;
