import { useState, useEffect } from "react";
import NavBar from "../navbar/navbar";
import "./leadProfile.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function LeadProfile() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [info, setInfo] = useState({});
  const [Error, setError] = useState(false);

  const undisplayableValus = ["user_id", "_id"];

  useEffect(() => {
    const token = get_profile();
    get_leads(token);
  }, []);

  function get_profile() {
    var token = localStorage.getItem("orkit_token");
    if (!token) {
      navigate("/Login");
      return;
    }
    token = token.slice(1, -1);
    if (!token) {
      navigate("/Login");
      return;
    }
    return token;
  }

  function get_leads(token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    axios
      .get(process.env.REACT_APP_API_URL + "lead/" + id, { headers })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data);
        }
      })
      .catch((error) => {
        setError("An Error Occured");
      });
  }
  function capitalizeFirstLetter(str) {
    if (str.length === 0) return str; // Check for an empty string

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  function formatDate(date_string) {
    try {
      const date = new Date(date_string);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    } catch {
      return "-";
    }
  }
  return (
    <div>
      <NavBar />

      <main class="nxl-container">
        <div class="page-header">
          <div class="page-header-left d-flex align-items-center">
            <div class="page-header-title">
              <h5 class="m-b-10">Leads</h5>
            </div>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/templates/">Profile</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="nxl-content" id="leadProfielViewContent">
          <div class="main-content">
            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                id="profileTab"
                role="tabpanel"
              >
                <div class="card card-body lead-info">
                  <div class="mb-4 d-flex align-items-center justify-content-between">
                    <h5 class="fw-bold mb-0">
                      <span class="d-block mb-2">Lead Information :</span>
                      <span class="fs-12 fw-normal text-muted d-block">
                        Following information for your lead
                      </span>
                    </h5>
                  </div>
                  {info &&
                    Object.entries(info).map(([key, value]) => {
                      if (typeof value != "string") {
                        return;
                      }
                      if (undisplayableValus.includes(key)) {
                        return;
                      }
                      if (key == "created_at") {
                        value = formatDate(value);
                      }
                      return (
                        <div class="row mb-4">
                          <div class="col-lg-2 fw-medium">
                            {" "}
                            {capitalizeFirstLetter(key.replace(/_/g, " "))}
                          </div>
                          <div class="col-lg-10">
                            <a href="javascript:void(0);">
                              {" "}
                              {capitalizeFirstLetter(value)}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  <div class="mb-4 d-flex align-items-center justify-content-between">
                    <h5 class="fw-bold mb-0">
                      <span class="fs-12 fw-normal text-muted d-block mt-5">
                        Lead Dynamic Attributes
                      </span>
                    </h5>
                  </div>
                  {info.dynamic_data &&
                    Object.entries(info.dynamic_data).map(([key, value]) => {
                      if (typeof value != "string") {
                        return;
                      }
                      if (undisplayableValus.includes(key)) {
                        return;
                      }

                      return (
                        <div class="row mb-4">
                          <div class="col-lg-2 fw-medium">
                            {" "}
                            {capitalizeFirstLetter(key.replace(/_/g, " "))}
                          </div>
                          <div class="col-lg-10">
                            <a href="javascript:void(0);">
                              {" "}
                              {capitalizeFirstLetter(value)}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default LeadProfile;
