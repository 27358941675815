import "../assets/css/bootstrap.min.css";
import "../assets/vendors/css/vendors.min.css";
import "../assets/css/theme.min.css";
import { ReactComponent as LoginCover } from "../assets/images/Artboard.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import axios from "axios";

function Login() {
  document.documentElement.classList.add("app-skin-dark");
  const [error, setEroor] = useState(null);
  const [loading, setLoadins] = useState(false);
  const navigate = useNavigate();

  const [loginParms, setLoginParams] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPässword] = useState(false);

  function handleLogin(e) {
    setLoadins(true);
    e.preventDefault();

    const params = {
      email: loginParms.email,
      password: loginParms.password,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "login", params)
      .then((response) => {
        if (response.status === 200) {
          const serializedData = JSON.stringify(response.data.access_token);
          const serilizedUse = JSON.stringify(response.data.user);
          localStorage.setItem("orkit_token", serializedData);
          localStorage.setItem("orkit_user", serilizedUse);

          setLoadins(false);
          navigate("/Leads");
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          setEroor(error.response.data);
        } else {
          setEroor("An Error Occured");
        }
        setLoadins(false);
      });
  }

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  return (
    <div>
      <main className="auth-cover-wrapper">
        <div className="auth-cover-content-inner">
          <div className="auth-cover-content-wrapper">
            <div className="auth-img">
              <LoginCover
                style={{
                  width: "50vw",
                  height: "70vh",
                  transform: "translateX(-5vw)",
                }}
              />
            </div>
          </div>
        </div>
        <div className="auth-cover-sidebar-inner">
          <div className="auth-cover-card-wrapper">
            <div className="auth-cover-card p-sm-5">
              <div className="wd-50 mb-5">
                <img
                  src="./../assets/images/logo-abbr.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
              {error ? (
                <div class="alert alert-danger" role="alert">
                  {" "}
                  {error}
                </div>
              ) : (
                ""
              )}
              <h2 className="fs-20 fw-bolder mb-4">Login</h2>
              <h4 className="fs-13 fw-bold mb-2">Login to your account</h4>
              <p className="fs-12 fw-medium text-muted">
                Thank you for get back <strong>Nelel</strong> web applications,
                let's access our the best recommendation for you.
              </p>
              <form className="w-100 mt-4 pt-2">
                <div className="mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={(e) => {
                      setLoginParams((prevParams) => ({
                        ...prevParams,
                        email: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="mb-3 flex-direction-row d-flex">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    required
                    onChange={(e) => {
                      setLoginParams((prevParams) => ({
                        ...prevParams,
                        password: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="rememberMe"
                      />
                      <label
                        className="custom-control-label c-pointer"
                        for="rememberMe"
                      >
                        Remember Me
                      </label>
                    </div>
                  </div>
                  <div>
                    <a
                      href="auth-reset-cover.html"
                      className="fs-11 text-primary"
                    >
                      Forget password?
                    </a>
                  </div>
                </div>
                <div className="mt-5" onClick={handleLogin}>
                  {loading ? (
                    <button className="btn btn-lg btn-primary w-100">
                      <Bars
                        height="20"
                        width="40"
                        color="white"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button className="btn btn-lg btn-primary w-100">
                      Login
                    </button>
                  )}
                </div>
              </form>

              <div className="mt-5 text-muted">
                <span> Don't have an account?</span>
                <a href="/register" className="fw-bold">
                  Create an Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Login;
