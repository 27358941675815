import PNGICON from "../assets/images/file-icons/png.png";
import ZIPICON from "../assets/images/file-icons/zip.png";
import PDFICON from "../assets/images/file-icons/pdf.png";
import PSDICON from "../assets/images/file-icons/psd.png";

function ComingSoon() {
  return (
    <div class="row">
      <div class="col-sm-6">
        <div class="card stretch stretch-full">
          <div class="card-body p-0 ht-200 position-relative">
            <a
              href="javascript:void(0);"
              class="w-100 h-100 d-flex align-items-center justify-content-center"
            >
              <img src={ZIPICON} class="img-fluid wd-80 ht-80" alt="" />
            </a>
          </div>
          <div class="card-footer p-4">
            <h2 class="fs-13 mb-1 text-truncate-1-line">Hubspot</h2>
            <small class="fs-10 text-uppercase">
              <span class="text-gray-500">Coming Soon</span>
            </small>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card stretch stretch-full">
          <div class="card-body p-0 ht-200 position-relative">
            <a
              href="javascript:void(0);"
              class="w-100 h-100 d-flex align-items-center justify-content-center"
            >
              {" "}
              <img src={PNGICON} class="img-fluid wd-80 ht-80" alt="" />{" "}
            </a>
          </div>
          <div class="card-footer">
            <h2 class="fs-13 mb-1 text-truncate-1-line">Mailchimp</h2>
            <small class="fs-10 text-uppercase">
              <span class="text-gray-500">Coming Soon</span>
            </small>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card stretch stretch-full">
          <div class="card-body p-0 ht-200 position-relative">
            <a class="w-100 h-100 d-flex align-items-center justify-content-center">
              {" "}
              <img src={PDFICON} class="img-fluid wd-80 ht-80" alt="" />{" "}
            </a>
          </div>
          <div class="card-footer">
            <h2 class="fs-13 mb-1 text-truncate-1-line">SalesForce</h2>
            <small class="fs-10 text-uppercase">
              <span class="text-gray-500">Coming Soon</span>
            </small>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card mb-4 stretch stretch-full">
          <div class="card-body p-0 ht-200 position-relative">
            <a
              href="javascript:void(0);"
              class="w-100 h-100 d-flex align-items-center justify-content-center"
            >
              {" "}
              <img src={PSDICON} class="img-fluid wd-80 ht-80" alt="" />{" "}
            </a>
          </div>
          <div class="card-footer">
            <h2 class="fs-13 mb-1 text-truncate-1-line">Zappier</h2>
            <small class="fs-10 text-uppercase">
              <span class="text-gray-500">Coming Soon</span>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
