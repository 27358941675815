import { useState } from "react";
import { ReactComponent as Svgimage } from "../assets/images/auth/auth-cover-register-bg.svg";
import "quill/dist/quill.snow.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { ReactComponent as LoginCover } from "../assets/images/Artboard.svg";

function Register() {
  const [showPassword, setShowPässword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [authParms, setAuthParams] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  function handleLogin(e) {
    setLoading(true);
    e.preventDefault();

    if (authParms.confirmPassword !== authParms.password) {
      return;
    }
    if (authParms.email.length === 0 || authParms.password.length === 0) {
      setError("No Empty Fields Allowed");
      return;
    }

    const params = {
      first_name: "",
      last_name: "",
      email: authParms.email,
      password: authParms.password,
      business_name: "",
    };
    console.log(process.env.REACT_APP_API_URL + "register");
    axios
      .post(process.env.REACT_APP_API_URL + "register", params)
      .then((response) => {
        navigate("/Login");
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response && error.response.data) {
          setError(error.response.data.message);
        } else {
          setError("An error Occured , Please Try Again Later");
        }
        setLoading(false);
      });
  }
  return (
    <body>
      <main class="auth-cover-wrapper">
        <div class="auth-cover-content-inner">
          <div class="auth-cover-content-wrapper">
            <div class="auth-img">
              <LoginCover
                style={{
                  width: "50vw",
                  height: "70vh",
                  transform: "translateX(-5vw)",
                }}
              />
            </div>
          </div>
        </div>

        <div class="auth-cover-sidebar-inner">
          <div class="auth-cover-card-wrapper">
            <div class="auth-cover-card p-sm-5">
              {error ? (
                <div class="alert alert-danger" role="alert">
                  {" "}
                  {error}
                </div>
              ) : (
                ""
              )}
              <h2 class="fs-20 fw-bolder mb-4">Register</h2>
              <h4 class="fs-13 fw-bold mb-2">Manage all your nexel crm</h4>
              <p class="fs-12 fw-medium text-muted">
                Let's get you all setup, so you can verify your personal account
                and begine setting up your profile.
              </p>
              <form class="w-100 mt-4 pt-2">
                <div class="mb-4">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    required
                    onChange={(e) => {
                      setAuthParams((prevParams) => ({
                        ...prevParams,
                        email: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div class="mb-4 generate-pass">
                  <div class="input-group field">
                    <input
                      type={showPassword ? "text" : "password"}
                      class="form-control password"
                      id="newPassword"
                      placeholder="Password"
                      onChange={(e) => {
                        setAuthParams((prevParams) => ({
                          ...prevParams,
                          password: e.target.value,
                        }));
                      }}
                    />

                    <div
                      class="input-group-text border-start bg-gray-2 c-pointer show-pass"
                      data-bs-toggle="tooltip"
                      title="Show/Hide Password"
                      onClick={() => {
                        setShowPässword(!showPassword);
                      }}
                    >
                      <i></i>
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <input
                    type={showPassword ? "text" : "password"}
                    class="form-control"
                    placeholder="Password again"
                    required
                    onChange={(e) => {
                      setAuthParams((prevParams) => ({
                        ...prevParams,
                        confirmPassword: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div class="mt-4">
                  <div class="custom-control custom-checkbox mb-2">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="receiveMial"
                      required
                    />
                    <label
                      class="custom-control-label c-pointer text-muted"
                      for="receiveMial"
                      style={{ "font-weight": "400 !important" }}
                    >
                      Yes, I wnat to receive nexel community emails
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="termsCondition"
                      required
                    />
                    <label
                      class="custom-control-label c-pointer text-muted"
                      for="termsCondition"
                      style={{ "font-weight": "400 !important" }}
                    >
                      I agree to all the <a href="">Terms &amp; Conditions</a>{" "}
                      and <a href="">Fees</a>.
                    </label>
                  </div>
                </div>
                <div class="mt-5">
                  {loading ? (
                    <button className="btn btn-lg btn-primary w-100">
                      <Bars
                        height="20"
                        width="40"
                        color="white"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </button>
                  ) : (
                    <button
                      class="btn btn-lg btn-primary w-100"
                      onClick={handleLogin}
                    >
                      Create Account
                    </button>
                  )}
                </div>
              </form>
              <div class="mt-5 text-muted">
                <span>Already have an account?</span>
                <a href="/login" class="fw-bold">
                  Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </body>
  );
}

export default Register;
