import { useState, useEffect } from "react";
import { Bars } from "react-loader-spinner";

function DemoTable(props) {
  const [demoRow, setDemoRows] = useState(null);
  const saveFile = props.save_file;
  const Error = props.error;
  const Loading = props.loading;

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const lines = text.split(/\r\n|\n/);
      const rows = lines.map((l) => l.split(","));
      setDemoRows(rows.slice(0, 5));
    };
    reader.readAsText(props.file);
  }, []);

  return (
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-hover" id="leadList">
                <thead>
                  <tr>
                    {demoRow &&
                      demoRow[0].map((title) => {
                        return <th>{title}</th>;
                      })}
                  </tr>
                </thead>

                <tbody>
                  {demoRow &&
                    demoRow.slice(1).map((row) => {
                      return (
                        <tr class="single-item">
                          {row &&
                            row.map((l) => {
                              return (
                                <td>
                                  <div>
                                    <span class="text-truncate-1-line">
                                      {l}
                                    </span>
                                  </div>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="cols justify-content-center ">
            <div class="col-auto">
              {!Loading ? (
                <button className="btn btn-primary ms-auto" onClick={saveFile}>
                  Save
                </button>
              ) : (
                <button className="btn btn-primary ms-auto" onClick={saveFile}>
                  <Bars
                    height="20"
                    width="40"
                    color="white"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex"></div>
      </div>
    </div>
  );
}

export default DemoTable;
