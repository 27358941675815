import { useEffect, useState } from "react";
import "quill/dist/quill.snow.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import NavBar from "../navbar/navbar";
import Header from "../Header/header";
function Profile() {
  const navigate = useNavigate();

  const [isModify, setIsModify] = useState(false);
  const [isUpdatingAttributes, setIsUpdatingAttributes] = useState(false);
  const [modifiedInfo, setModifiedInfo] = useState({ attribute_values: {} });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const nonModifiableKeys = ["created_at", "number_of_emails"];

  const [info, setInfo] = useState({});

  useEffect(() => {
    const token = get_token();
    get_profile(token);
  }, []);

  function save_update() {
    setLoading(true);
    let q = modifiedInfo;

    q.attribute_values = { ...q.attribute_values, ...info.attribute_values };

    if (Object.keys(q.attribute_values).length == 0) {
      delete q.attribute_values;
    }

    const token = get_token();

    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    axios
      .post(process.env.REACT_APP_API_URL + "update_profile", q, { headers })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.user);
          setIsModify(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError("An Error Occured");
        setIsModify(false);
        setLoading(false);
      });
  }

  function get_token() {
    var token = localStorage.getItem("orkit_token");
    if (!token) {
      navigate("/Login");
      return;
    }
    token = token.slice(1, -1);
    if (!token) {
      navigate("/Login");
      return;
    }
    return token;
  }

  function get_profile(token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    axios
      .get(process.env.REACT_APP_API_URL + "profile", { headers })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data);
        }
      })
      .catch((error) => {
        setError(true);
      });
  }

  const addEmptyAttributeField = () => {
    setModifiedInfo((prev) => ({
      ...prev,
      attribute_values: {
        ...prev.attribute_values,
        emptyvalue: null,
      },
    }));
  };
  const addAttribute = () => {
    const key_el = document.getElementById("dynamicAttributeKey");
    const value_el = document.getElementById("dynamicAttributeValue");
    if (!key_el || !value_el) {
      alert("no key or value detected");
    }
    const key = key_el.value;
    const value = value_el.value;

    let attr = modifiedInfo.attribute_values;
    delete attr["emptyvalue"];
    attr[key] = value;
    setModifiedInfo((prev) => ({
      ...prev,
      attribute_values: attr,
    }));
  };
  const updateInfoAttribute = (target_attribte_key, new_value) => {
    setModifiedInfo((prev) => ({
      ...prev,
      [target_attribte_key]: new_value,
    }));
  };
  function capitalizeFirstLetter(str) {
    if (!str) {
      return "";
    }
    if (str.length === 0) return str; // Check for an empty string

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  function delete_dynamic_attribute(key) {
    let q = info.attribute_values;
    delete q[key];
    setInfo((prev) => ({
      ...prev,
      attribute_values: q,
    }));
  }

  return (
    <div>
      <Header />
      <NavBar />

      <main class="nxl-container">
        <div class="nxl-content" id="leadProfielViewContent">
          <div class="main-content">
            <div class="tab-content">
              <div
                class="tab-pane fade show active"
                id="profileTab"
                role="tabpanel"
              >
                <div class="card card-body lead-info">
                  <div class="mb-4 d-flex align-items-center justify-content-between">
                    <h5 class="fw-bold mb-0">
                      <span class="d-block mb-2">Profile Information :</span>
                      <span class="fs-12 fw-normal text-muted d-block">
                        Following information for your Profile
                      </span>
                    </h5>
                    {!isModify ? (
                      <i
                        style={{ cursor: "pointer" }}
                        class="feather-edit"
                        onClick={() => {
                          setIsModify(true);
                        }}
                      ></i>
                    ) : (
                      <div class="d-flex align-items-center">
                        {loading ? (
                          <button className="btn btn-lg btn-primary  me-3">
                            <Bars
                              height="15"
                              width="20"
                              color="white"
                              ariaLabel="bars-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          </button>
                        ) : (
                          <button
                            class="btn btn-primary me-3"
                            onClick={() => {
                              save_update();
                            }}
                          >
                            <span>Save</span>
                          </button>
                        )}
                        <i
                          style={{
                            cursor: "pointer",
                            color: "red",
                            fontSize: "20px",
                          }}
                          class="feather-x"
                          onClick={() => {
                            setIsModify(false);
                          }}
                        ></i>
                      </div>
                    )}
                  </div>
                  {error ? (
                    <div class="alert alert-danger" role="alert">
                      {" "}
                      An Error Happened Please Try Again Later
                    </div>
                  ) : (
                    ""
                  )}
                  {Object.entries(info).map(([key, value]) => {
                    //fancy way t ocheck if variable is not an object : {}
                    if (key == "_id") {
                      return;
                    }
                    if (typeof value == "number") {
                      value = String(value);
                    }

                    if (typeof value !== "string") {
                      return;
                    }
                    if (value.length == 0) {
                      value = "-";
                    }

                    if (["created_at", "modified_at"].includes(key)) {
                      try {
                        const date = new Date(value);
                        const options = {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        };
                        value = date.toLocaleDateString("en-US", options);
                      } catch {}
                    }
                    return (
                      <div class="row mb-0 p-2">
                        <div class="col-lg-2 fw-medium">
                          {capitalizeFirstLetter(key.replace(/_/g, " "))}
                        </div>
                        {isModify && !nonModifiableKeys.includes(key) ? (
                          <input
                            class="col-lg-5 rounded p-1"
                            style={{
                              outline: "none",
                              borderColor: "transparent",
                              border: "none",
                            }}
                            placeholder={capitalizeFirstLetter(value)}
                            onChange={(e) => {
                              updateInfoAttribute(key, e.target.value);
                            }}
                          />
                        ) : (
                          <div class="col-lg-10">
                            <a href="javascript:void(0);">
                              {capitalizeFirstLetter(value)}{" "}
                            </a>
                          </div>
                        )}
                      </div>
                    );
                  })}

                  <div class="mb-4 d-flex align-items-center justify-content-between mt-5 ">
                    <span class="fs-12 fw-normal text-muted d-block ">
                      Additional Dynamic Points
                    </span>
                    {isModify && (
                      <i
                        style={{ cursor: "pointer", fontSize: "20px" }}
                        class="feather-plus"
                        onClick={addEmptyAttributeField}
                      ></i>
                    )}
                  </div>
                  {info.attribute_values &&
                    Object.entries(info.attribute_values).map(
                      ([key, value]) => {
                        return (
                          <div class="row mb-0 p-2">
                            <div class="col-lg-2 fw-medium">
                              {capitalizeFirstLetter(key)}
                            </div>

                            <div class="col-lg-9">
                              <a>{capitalizeFirstLetter(value)}</a>
                            </div>
                            {isModify && (
                              <i
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                  color: "red",
                                }}
                                class="feather-x col-lg-1"
                                onClick={() => {
                                  delete_dynamic_attribute(key);
                                }}
                              ></i>
                            )}
                          </div>
                        );
                      }
                    )}
                  {modifiedInfo.attribute_values &&
                    Object.entries(modifiedInfo.attribute_values).map(
                      ([key, value]) => {
                        return (
                          <div class="row mb-0 p-2 d-flex flex-row align-items-center">
                            {key != "emptyvalue" ? (
                              <div class="col-lg-2 fw-medium">
                                {capitalizeFirstLetter(key)}
                              </div>
                            ) : (
                              <input
                                class="col-lg-2 rounded p-1 ms-2"
                                style={{
                                  outline: "none",
                                  borderColor: "transparent",
                                  border: "none",
                                }}
                                placeholder="Attribute"
                                id="dynamicAttributeKey"
                              />
                            )}
                            {key != "emptyvalue" ? (
                              <div class="col-lg-10">
                                <a href="javascript:void(0);">
                                  {capitalizeFirstLetter(value)}
                                </a>
                              </div>
                            ) : (
                              <input
                                class="col-lg-3 rounded p-1"
                                style={{
                                  outline: "none",
                                  borderColor: "transparent",
                                  border: "none",
                                }}
                                placeholder="Value"
                                id="dynamicAttributeValue"
                              />
                            )}
                            {key == "emptyvalue" ? (
                              <i
                                style={{
                                  cursor: "pointer",
                                  width: "fit-content",
                                }}
                                class="feather-check"
                                onClick={addAttribute}
                              ></i>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      }
                    )}
                </div>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Profile;
