import "./displayEmail.css";
function DisplayEmail({ params, killDisplayEmailPannel }) {
  console.log(params);
  return (
    <div className="displayEmailPannel">
      <section class="step-body mt-4 mb-4">
        <div class="modal-header">
          <h2 class="d-flex flex-column mb-0">
            <span class="fs-18 fw-bold mb-1">Mail</span>
          </h2>
          <a
            class="avatar-text avatar-md bg-soft-danger close-icon"
            onClick={killDisplayEmailPannel}
          >
            <i class="feather-x text-danger"></i>
          </a>
        </div>
      </section>
      <div class="px-3 w-100 d-flex align-items-center mb-2">
        <input
          class="form-control border-0 my-1 w-100 shadow-none"
          type="email"
          id="emailSubjectWrapper"
          value={params.to_email}
        />
      </div>
      <div class="px-3 w-100 d-flex align-items-center mb-2">
        <input
          class="form-control border-0 my-1 w-100 shadow-none"
          type="email"
          id="emailSubjectWrapper"
          value={params.subject}
        />
      </div>
      <div class="px-3 d-flex align-items-center mb-2">
        <textarea
          class="form-control border-0 my-1 w-100 h-full shadow-none"
          type="email"
          placeholder="AI Prompt"
          style={{ height: "60vh", maxHeight: "60vh" }}
          value={params.content}
        />
      </div>
    </div>
  );
}

export default DisplayEmail;
