import NavBar from "../navbar/navbar";
import { useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./compose.css";
import ComposePannel from "./composepannel";
import EmailPannel from "./emailPannel";
import DisplayEmail from "./displayEmail/dispalyEmail";
import Header from "../Header/header";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Compose() {
  const navigate = useNavigate;
  const [error, setError] = useState(false);
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [filter, setFilter] = useState("");
  const [emails, setEmails] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreLines, setHasMoreLines] = useState(false);
  const [displayEmailDisplayPannel, setDisplayEmailDisplayPannel] = useState();
  const [displayComposePannel, setDisplayCOmposePannel] = useState(false);
  const [displayEmailPannel, setDisplayEmailPannel] = useState(false);
  const [currentEmailIndex, setCurrentEmailIndex] = useState(1);
  const [recipientsList, setRecipientList] = useState([]);
  const [initialPrompt, setInitialPrompt] = useState("");
  const [currentEmail, setCurrentEmail] = useState([]);

  function triggerimpot() {
    var content = document.getElementById("mainBlurrableContent");
    content.classList.add("blur-effect");
    setDisplayCOmposePannel(true);
  }
  function killImportPannel() {
    var content = document.getElementById("mainBlurrableContent");
    content.classList.remove("blur-effect");
    setDisplayCOmposePannel(false);
  }
  function killEmailPannel() {
    var content = document.getElementById("mainBlurrableContent");
    content.classList.remove("blur-effect");
    setDisplayEmailPannel(false);
  }

  useEffect(() => {
    GenerateEmail();
  }, [recipientsList]);

  useEffect(() => {
    const token = get_token();
    get_sent_emails(token);
  }, [page]);

  function get_token() {
    var token = localStorage.getItem("orkit_token");
    if (!token) {
      navigate("/Login");
      return;
    }
    token = token.slice(1, -1);
    if (!token) {
      navigate("/Login");
      return;
    }
    return token;
  }

  function get_sent_emails(token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
    const body = {
      max_records: 5,
      page: page,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "emails", body, { headers })
      .then((response) => {
        if (response.status === 200) {
          setHasMoreLines(response.data.stats.has_more);
          setEmails(response.data.emails);
        }
      })
      .catch((error) => {
        setError(true);
      });
  }

  function GenerateEmail() {
    if (displayComposePannel == true && displayEmailPannel == false) {
      setDisplayCOmposePannel(false);
      setDisplayEmailPannel(true);
    }
  }
  function collapseimportPannel(event) {
    const nestedElement = document.querySelector(".composePannelWrapp");

    if (nestedElement && nestedElement.contains(event.target)) {
      return;
    } else {
      if (displayComposePannel) {
        killImportPannel();
      }
    }
  }
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);

  const maxPage = Math.ceil(emails.length / itemsPerPage);

  const goToNextPage = () => {
    if (!hasMoreLines) {
      return;
    }
    setPage(page + 1);
  };

  const goToPreviousPage = () => {
    if (page == 1) {
      return;
    }
    setPage(page - 1);
  };

  const getCurrentData = () => {
    let e;
    if (filter == "sent") {
      e = emails.filter((item) => item.sent == "true");
    } else {
      e = emails;
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return e.slice(startIndex, endIndex);
  };

  return (
    <div class="pace-done">
      <NavBar />
      <Header />

      <div
        className="nxl-container apps-container apps-email"
        id="mainBlurrableContent"
        onClick={(e) => {
          collapseimportPannel(e);
        }}
      >
        <div class="nxl-content without-header nxl-full-content">
          <div className="main-content d-flex">
            <div
              className="content-area ps ps--active-y"
              data-scrollbar-target="#psScrollbarInit"
            >
              <div class="content-area-header bg-white sticky-top">
                <div class="page-header-left d-flex align-items-center gap-2">
                  <button
                    class="btn btn-primary"
                    onClick={() => {
                      triggerimpot();
                    }}
                  >
                    <i class="feather-plus me-2"></i>
                    <span>Compose</span>
                  </button>
                </div>
                <div class="page-header-right ms-auto d-flex">
                  <div class="dropdown show">
                    <a
                      class="btn  dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      onClick={() => {
                        setShowStatusFilter(!showStatusFilter);
                      }}
                    >
                      <i class="feather-filter"></i>
                    </a>

                    {showStatusFilter && (
                      <div
                        class="dropdown-menu show "
                        aria-labelledby="dropdownMenuLink"
                      >
                        <div
                          class="dropdown-item"
                          onClick={() => {
                            setShowStatusFilter(false);
                            setFilter("generated");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Generated
                        </div>
                        <div
                          class="dropdown-item"
                          onClick={() => {
                            setShowStatusFilter(false);
                            setFilter("sent");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Sent
                        </div>
                      </div>
                    )}
                  </div>
                  <div class="hstack gap-2">
                    <div class="hstack d-none d-sm-flex">
                      <a class="d-flex me-1">
                        <div
                          class="avatar-text avatar-md"
                          data-bs-toggle="tooltip"
                          data-bs-trigger="hover"
                          title=""
                          data-bs-original-title="Previous"
                          onClick={goToPreviousPage}
                          disabled={currentPage === 1}
                        >
                          <i class="feather-chevron-left"></i>
                        </div>
                      </a>
                      <a
                        aria-controls="leadList"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link fs-10 me-2"
                      >
                        {page}
                      </a>
                      <a class="d-flex me-1">
                        <div
                          class="avatar-text avatar-md"
                          title=""
                          onClick={goToNextPage}
                          disabled={currentPage === maxPage}
                        >
                          <i class="feather-chevron-right"></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-area-body p-0">
                {error ? (
                  <div
                    class="alert alert-danger p-2"
                    role="alert"
                    style={{ width: "fit-content", margin: "auto" }}
                  >
                    An Error Happened, Please Try Again Later
                  </div>
                ) : (
                  ""
                )}
                {getCurrentData().map((e, i) => {
                  return (
                    <div
                      class="single-items"
                      onClick={() => {
                        console.log(i);
                        setDisplayEmailDisplayPannel(i);
                      }}
                    >
                      <div class="d-flex wd-80 gap-4 ms-1 item-meta">
                        <div class="item-favorite">
                          <a>
                            <i
                              class="feather-circle fs-12"
                              style={
                                e.sent
                                  ? { color: "yellow" }
                                  : { color: "green" }
                              }
                            ></i>
                          </a>
                        </div>
                      </div>

                      <div
                        class="d-flex align-items-center gap-4 w-100 item-info"
                        data-view-toggle="details"
                      >
                        <a class="hstack gap-3 wd-200 item-user">
                          <div class="text-truncate-1-line mb-0">
                            {e.to_email}
                          </div>
                        </a>
                        <a class="d-none d-md-block">
                          <div class="w-100 text-truncate-1-line item-desc">
                            <span class="badge bg-gray-200 text-dark me-2">
                              {e.tag}
                            </span>
                            <i class="feather-link-2 fs-12"></i>
                            <span class="ms-3">{e.subject}</span>
                          </div>
                        </a>
                      </div>
                      <div class="d-flex align-items-center justify-content-end wd-150 gap-3 item-data">
                        <div class="fs-11 fw-medium text-muted text-uppercase d-none d-sm-block item-time">
                          {e.sent_date}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {getCurrentData().length === 0 ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ marginTop: "10vh" }}
                  >
                    <span>No Emails</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {displayComposePannel && (
        <ComposePannel
          killImportPannel={killImportPannel}
          generateEmail={GenerateEmail}
          setRecipientList={setRecipientList}
          setInitialPrompt={setInitialPrompt}
        />
      )}
      {displayEmailPannel && (
        <EmailPannel
          killEmailPannel={killEmailPannel}
          data={currentEmail[currentEmailIndex]}
          setCurrentEmailIndex={setCurrentEmailIndex}
          recipientsList={recipientsList}
          initialPrompt={initialPrompt}
        />
      )}
      {displayEmailDisplayPannel != null && (
        <DisplayEmail
          params={emails[displayEmailDisplayPannel]}
          killDisplayEmailPannel={() => {
            setDisplayEmailDisplayPannel(null);
          }}
        />
      )}
    </div>
  );
}

export default Compose;
