import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./login/login";
import Leads from "./Leads/mailsender";
import LeadProfile from "./LeadProfile/leadProfile";
import Compose from "./Compose/compose";
import Register from "./register/register";
import Profile from "./Profile/profile";
function App() {
  document.documentElement.classList.add("app-skin-dark");

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/Leads/profile/:id" element={<LeadProfile />} />
          <Route path="/Compose" element={<Compose />} />
          <Route path="/Leads" element={<Leads />} />
          <Route path="/Profile" element={<Profile />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
