import "./email.css";
import TextEditor from "./quilltext";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { Bars } from "react-loader-spinner";

function EmailPannel(props) {
  const killEmailPannel = props.killEmailPannel;
  const recipient_list = props.recipientsList;
  const initialPrompt = props.initialPrompt;
  const [loading, setLoading] = useState(true);
  const [regenerationPrompt, setRegenerationPrompt] = useState([]);
  const [displayedEmailContent, setDiplaeydEmailContent] = useState("");
  const [comingSoonPannel, setComingSoonPannel] = useState(false);
  const [regenerationLoading, setRegenerateLaoding] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [mailError, setMailError] = useState(false);

  const [currentRecipient, setCurrentRecicipient] = useState(0);
  const [currenEmailContet, setCurrenEmailContet] = useState({
    email_content: "",
    email_subject: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const token = get_token();
    generate_email(token);
  }, [recipient_list, currentRecipient]);

  function get_token() {
    var token = localStorage.getItem("orkit_token");
    if (!token) {
      navigate("/Login");
      return;
    }
    token = token.slice(1, -1);
    if (!token) {
      navigate("/Login");
      return;
    }
    return token;
  }

  function get_token() {
    var token = localStorage.getItem("orkit_token");
    if (!token) {
      navigate("/Login");
      return;
    }
    token = token.slice(1, -1);
    if (!token) {
      navigate("/Login");
      return;
    }
    return token;
  }

  function sendEmail() {
    setSendLoading(true);

    const token = get_token();

    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    const body = {
      content: currenEmailContet.email_content,
      subject: currenEmailContet.email_subject,
      destination_email: recipient_list[currentRecipient].email,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "send_email", body, { headers })
      .then((response) => {
        if (response.status === 200) {
          setMailSent(true);
          setSendLoading(false);
        }
      })
      .catch((error) => {
        setError("Failed To Send Email, Try Again Later");
        setSendLoading(false);
        setMailError(true);
      });
  }

  function generate_email(token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    if (!recipient_list || recipient_list.length === 0) {
      return;
    }

    const body = {
      recipient_id: recipient_list[currentRecipient]._id,
      prompt: initialPrompt,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "generate_email", body, { headers })
      .then((response) => {
        setCurrenEmailContet(response.data);
        setLoading(false);
        setNextLoading(false);
      })
      .catch((error) => {
        setError("An Error Occured Please Try Again Later");
        setLoading(false);
      });
  }

  function regenerate_email() {
    setRegenerateLaoding(true);
    var token = get_token();
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    var prev = displayedEmailContent;
    if (displayedEmailContent.length == 0) {
      prev = currenEmailContet.email_content;
    }
    const body = {
      recipient_id: recipient_list[currentRecipient]._id,
      prompt: regenerationPrompt,
      previous_email_content: prev,
      email_subject: currenEmailContet.email_subject,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "regenerate_email", body, {
        headers,
      })
      .then((response) => {
        var new_val = {
          email_subject: response.data.email_subject,
          email_content: response.data.new_email,
        };
        setCurrenEmailContet(new_val);
        document.getElementsByClassName("");
        setRegenerateLaoding(false);
      })
      .catch((error) => {
        setRegenerateLaoding(false);
      });
  }

  return (
    <div className="emailPannel" id="emailPannel">
      <section class="step-body mb-4">
        <div class="modal-header">
          <h2 class="d-flex flex-column mb-0">
            <span class="fs-18 fw-bold mb-1">Mail</span>
          </h2>
          {error && <button className="btn btn-danger">{error}</button>}
          <a
            class="avatar-text avatar-md bg-soft-danger close-icon"
            onClick={killEmailPannel}
          >
            <i class="feather-x text-danger"></i>
          </a>
        </div>
      </section>

      <div class="px-3 w-100 d-flex align-items-center">
        <input
          class="form-control border-0 my-1 w-100 shadow-none"
          type="email"
          id="emailSubjectWrapper"
          value={
            recipient_list &&
            recipient_list[currentRecipient] &&
            recipient_list[currentRecipient].email
          }
        />
      </div>
      {!loading ? (
        <div>
          <div class="px-3 w-100 d-flex align-items-center">
            <input
              class="form-control border-0 my-1 w-100 shadow-none"
              type="email"
              id="emailSubjectWrapper"
              placeholder="Subject"
              value={currenEmailContet.email_subject}
            />
          </div>

          <TextEditor
            class=" mt-5"
            content={currenEmailContet}
            setDiplaeydEmailContent={setDiplaeydEmailContent}
          />
        </div>
      ) : (
        <div
          class="d-flex justify-content-center align-items-center h-50"
          id="emailPannelLoadingWrapper"
        >
          <Bars
            height="40"
            width="40"
            color="white"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {!loading ? (
        <div class="px-3 w-100 d-flex align-items-center">
          <input
            class="form-control border-0 my-1 w-100 shadow-none"
            placeholder="Regenerate Promp"
            id="regeneratePrompt"
            onChange={(e) => {
              setRegenerationPrompt(e.target.value);
            }}
          />
          {!regenerationLoading ? (
            <button
              className="btn btn-primary ms-3"
              onClick={(e) => {
                regenerate_email();
              }}
            >
              ReGenerate
            </button>
          ) : (
            <button className="btn btn-primary ms-3">
              <Bars
                height="20"
                width="40"
                color="white"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </button>
          )}
        </div>
      ) : (
        ""
      )}

      {!loading ? (
        <div class="container mt-3 mb-0">
          <div class="d-flex flex-row justify-content-end">
            {!sendLoading && !mailSent && !mailError && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  sendEmail();
                }}
              >
                Send
              </button>
            )}
            {!sendLoading && mailSent && (
              <button className="btn btn-success" style={{ cursor: "none" }}>
                Succesfully Sent
              </button>
            )}
            {!sendLoading && mailError && (
              <button
                className="btn btn-danger"
                onClick={() => {
                  sendEmail();
                }}
              >
                Send Again
              </button>
            )}
            {sendLoading && !mailSent && (
              <button className="btn btn-primary">
                <Bars
                  height="20"
                  width="40"
                  color="white"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </button>
            )}

            {!nextLoading ? (
              <button
                className="btn btn-secondary ms-3"
                onClick={() => {
                  setNextLoading(true);
                  setComingSoonPannel(false);
                  setMailSent(false);
                  setMailError(false);
                  setError("");
                  setCurrenEmailContet({
                    email_content: "",
                    email_subject: "",
                  });
                  setCurrentRecicipient(currentRecipient + 1);
                }}
              >
                Next
              </button>
            ) : (
              <button className="btn btn-primary ms-3">
                <Bars
                  height="20"
                  width="40"
                  color="white"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </button>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default EmailPannel;
