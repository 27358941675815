import NavBar from "../navbar/navbar";
import { useState, useEffect } from "react";
import UploadPopUp from "../UploadPopUp/uploadpopup";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./mailsender.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../Header/header";
import "bootstrap/dist/css/bootstrap.min.css";
import { get, readyException } from "jquery";
import { Bars } from "react-loader-spinner";

function Email() {
  let navigate = useNavigate();

  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [DropdownTagsFilter, setDropDownTagsFilter] = useState(false);
  const [importFileType, setImportFileType] = useState(null);
  const [filter, setFilter] = useState({
    tag: [],
    search: "",
    page_number: 1,
  });
  const [hasMoreLines, setHasMoreLines] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [leads, setLeads] = useState([]);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const [searchInputContent, setSearchInputContent] = useState(null);
  const [searchLoding, setSearchLoading] = useState(false);
  const [stats, setStats] = useState(false);

  useEffect(() => {
    const token = get_token();
    get_leads(token);
  }, [filter]);
  useEffect(() => {
    const token = get_token();
    get_stats(token);
    get_tags(token);
  }, []);

  function get_token() {
    var token = localStorage.getItem("orkit_token");
    if (!token) {
      navigate("/Login");
      return;
    }
    token = token.slice(1, -1);
    if (!token) {
      navigate("/Login");
      return;
    }
    return token;
  }

  function get_leads(token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    const body = {
      tags: filter.tag,
      search_query: filter.search,
      page: filter.page_number,
      max_records: 5,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "leads_pagination", body, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          setLeads(response.data.leads);
          setHasMoreLines(response.data.stats.has_more);
          setSearchLoading(false);
        }
      })
      .catch((error) => {
        setError("An Error Occured");
        setSearchLoading(false);
      });
  }

  function get_stats(token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    axios
      .get(process.env.REACT_APP_API_URL + "leads/stats", {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          setStats(response.data.stats);
        }
      })
      .catch((error) => {
        setError("An Error Occured");
      });
  }

  function get_tags(token) {
    const headers = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    axios
      .get(process.env.REACT_APP_API_URL + "UniqueTags", { headers })
      .then((response) => {
        if (response.status === 200) {
          setTags(response.data.unique_tags);
        }
      })
      .catch((error) => {
        setError("An Error Occured");
      });
  }

  const routeToEdit = (i) => {
    const id = leads[i]._id;
    navigate("/leads/profile/" + id);
  };

  function triggerimpot(type) {
    var content = document.getElementById("headerContainer");
    setImportFileType(type);

    if (!content) {
      return;
    }

    content.classList.add("blur-effect");
  }

  function killImportPannel() {
    var content = document.getElementById("headerContainer");
    setImportFileType("");

    if (!content) {
      return;
    }
    content.classList.remove("blur-effect");
  }

  function collapseimportPannel(event) {
    const nestedElement = document.querySelector(".ImportWrapper");

    if (nestedElement && nestedElement.contains(event.target)) {
      return;
    } else {
      if (importFileType) {
        killImportPannel();
      }
    }
  }

  const maxPage = leads ? Math.ceil(leads.length / itemsPerPage) : 0;

  const goToNextPage = () => {
    if (!hasMoreLines) {
      return;
    }
    setFilter((prev) => ({
      ...prev,
      page_number: filter.page_number + 1,
    }));
  };

  const goToPreviousPage = () => {
    console.log(filter.page_number);
    if (filter.page_number == 1) {
      return;
    }
    setFilter((prev) => ({
      ...prev,
      page_number: filter.page_number - 1,
    }));
  };

  function getCurrentData() {
    return leads;
  }
  function formatDate(date_string) {
    try {
      const date = new Date(date_string);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    } catch {
      return "-";
    }
  }
  return (
    <div>
      <NavBar />
      <Header />

      <main
        class="nxl-container"
        onClick={(e) => {
          collapseimportPannel(e);
        }}
      >
        <div class="nxl-content">
          <div class="page-header">
            <div class="page-header-left d-flex align-items-center">
              <div class="page-header-title">
                <h5 class="m-b-10">Leads</h5>
              </div>
              <ul class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li class="breadcrumb-item">Leads</li>
              </ul>
            </div>
            <div class="page-header-right ms-auto">
              <div class="page-header-right-items">
                <div class="d-flex d-md-none">
                  <a
                    href="javascript:void(0)"
                    class="page-header-right-close-toggle"
                  >
                    <i class="feather-arrow-left me-2"></i>
                    <span>Back</span>
                  </a>
                </div>

                <div class="d-flex align-items-center gap-2 page-header-right-items-wrapper flex-row align-items-center ">
                  <form
                    class="search-form search-form-active flex-row align-items-center rounded "
                    onSubmit={(e) => {
                      e.preventDefault();
                      const search_content =
                        document.getElementById("tasksSearch");
                      if (!search_content) {
                        return;
                      }
                      setSearchLoading(true);
                      setFilter((prev) => {
                        return {
                          ...prev,
                          search: search_content.value,
                        };
                      });
                    }}
                  >
                    <div
                      class="search-form-inner flex-row align-items-center"
                      id="searchinputFoor"
                    >
                      <input
                        type="search"
                        class="py-3 px-0 border-0 rounded"
                        id="tasksSearch"
                        placeholder="Search..."
                        onClick={(e) => {
                          e.target.value = "";
                        }}
                        onChange={(e) => {
                          setSearchInputContent(e.target.value);
                        }}
                      />
                    </div>
                  </form>
                  <div
                    class="avatar-text avatar-md"
                    data-bs-toggle="tooltip"
                    data-bs-trigger="hover"
                    title=""
                    data-bs-original-title="Search"
                    onClick={(e) => {
                      setSearchLoading(true);
                      setFilter((prev) => {
                        return {
                          ...prev,
                          search: searchInputContent,
                        };
                      });
                    }}
                  >
                    {searchLoding ? (
                      <Bars
                        height="20"
                        width="40"
                        color="white"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      <i class="feather-search"></i>
                    )}
                  </div>

                  <div class="dropdown show">
                    {tags && tags.length != 0 ? (
                      <a
                        class="btn btn-secondary dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => {
                          setDropDownTagsFilter(!DropdownTagsFilter);
                        }}
                      >
                        <i class="feather-filter"></i>
                      </a>
                    ) : (
                      ""
                    )}
                    {DropdownTagsFilter && (
                      <div
                        class="dropdown-menu show ;"
                        aria-labelledby="dropdownMenuLink"
                        id="DropdownTagsFilterMenu"
                      >
                        <div
                          class="dropdown-item"
                          onClick={() => {
                            setFilter((prev) => {
                              return {
                                ...prev,
                                tag: "",
                              };
                            });
                            setDropDownTagsFilter(false);
                          }}
                        >
                          -
                        </div>
                        {tags.map((t) => {
                          return (
                            <div
                              class="dropdown-item"
                              onClick={() => {
                                setFilter((prev) => {
                                  return {
                                    ...prev,
                                    tag: [t],
                                  };
                                });
                                setDropDownTagsFilter(false);
                              }}
                            >
                              {t}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <a
                    href="javascript:void(0);"
                    class="btn btn-icon btn-light-brand"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                  >
                    <i class="feather-bar-chart"></i>
                  </a>
                  <button
                    class="btn btn-primary"
                    onClick={() => {
                      triggerimpot("csv");
                    }}
                  >
                    <i class="feather-plus me-2"></i>
                    <span>Import Leads</span>
                  </button>
                </div>
              </div>

              <div class="d-md-none d-flex align-items-center">
                <a
                  href="javascript:void(0)"
                  class="page-header-right-open-toggle"
                >
                  <i class="feather-align-right fs-20"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            id="collapseOne"
            class="accordion-collapse collapse page-header-collapse"
          >
            <div class="accordion-body pb-2">
              <div class="row">
                <div class="col-xxl-3 col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center gap-3">
                          <div class="avatar-text avatar-xl rounded">
                            <i class="feather-users"></i>
                          </div>
                          <a href="javascript:void(0);" class="fw-bold d-block">
                            <span class="d-block">Total Leads</span>
                            <span class="fs-24 fw-bolder d-block">
                              {stats && stats.total_leads}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xxl-3 col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center gap-3">
                          <div class="avatar-text avatar-xl rounded">
                            <i class="feather-user-check"></i>
                          </div>
                          <a href="javascript:void(0);" class="fw-bold d-block">
                            <span class="d-block">Contacted Leads</span>
                            <span class="fs-24 fw-bolder d-block">
                              {stats && stats.contacted_leads}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {leads && leads.length != 0 ? (
            <div class="main-content" id="headerContainer">
              <div
                id="leadList_wrapper"
                class="dataTables_wrapper dt-bootstrap5 no-footer"
              >
                <div class="row">
                  <div class="col-lg-12">
                    <div class="card">
                      <div class="card-body p-0">
                        <div class="table-responsive">
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th>Lead</th>
                                <th>Email</th>
                                <th>Tags</th>
                                <th>Date</th>
                                <th class="text-end">Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {getCurrentData().map((l, i) => {
                                return (
                                  <tr class="single-item">
                                    <td
                                      onClick={() => {
                                        if (importFileType) {
                                          return;
                                        }
                                        routeToEdit(i);
                                      }}
                                    >
                                      <div>
                                        <span class="text-truncate-1-line">
                                          {l.name}
                                        </span>
                                      </div>
                                    </td>
                                    <td
                                      onClick={() => {
                                        if (importFileType) {
                                          return;
                                        }
                                        routeToEdit(i);
                                      }}
                                    >
                                      <a>{l.email}</a>
                                    </td>
                                    <td>
                                      <div
                                        class="hstack gap-2"
                                        onClick={() => {
                                          routeToEdit(i);
                                        }}
                                      >
                                        {l.tags.map((t) => {
                                          return (
                                            <span
                                              class="badge badge-success"
                                              style={{ background: "green" }}
                                            >
                                              {t}
                                            </span>
                                          );
                                        })}
                                      </div>
                                    </td>

                                    <td
                                      onClick={() => {
                                        if (importFileType) {
                                          return;
                                        }
                                        routeToEdit(i);
                                      }}
                                    >
                                      {formatDate(l.created_at)}
                                    </td>

                                    <td>
                                      <div class="hstack gap-2 justify-content-end">
                                        <div class="dropdown">
                                          <a
                                            href="javascript:void(0)"
                                            class="avatar-text avatar-md"
                                            data-bs-toggle="dropdown"
                                            data-bs-offset="0,21"
                                          >
                                            <i class="feather feather-more-horizontal"></i>
                                          </a>
                                          <ul class="dropdown-menu shown">
                                            <li>
                                              <a class="dropdown-item">
                                                <i class="feather feather-edit-3 me-3"></i>
                                                <span>Edit</span>
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div
                      class="dataTables_info"
                      id="leadList_info"
                      role="status"
                      aria-live="polite"
                    >
                      {leads.length} entries
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="leadList_paginate"
                    >
                      <ul class="pagination" style={{ justifyContent: "end" }}>
                        <li
                          class="paginate_button page-item previous disabled"
                          id="leadList_previous"
                          onClick={goToPreviousPage}
                          disabled={currentPage === 1}
                        >
                          <button class="page-link fs-10">Previous</button>
                        </li>
                        <li class="paginate_button page-item active">
                          <a
                            aria-controls="leadList"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link fs-10"
                          >
                            {filter.page_number}
                          </a>
                        </li>
                        <li
                          class="paginate_button page-item next disabled"
                          id="leadList_next"
                          onClick={goToNextPage}
                          disabled={currentPage === maxPage}
                        >
                          <button class="page-link fs-10">Next</button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              class="d-flex align-items-center justify-content-center custom-height"
              role="alert"
              style={{ height: "25vh" }}
            >
              {" "}
              <strong>You Have No Leads </strong>
            </div>
          )}
        </div>
        <footer class="footer">
          <p class="fs-11 text-muted fw-medium text-uppercase mb-0 copyright">
            <span>Copyright ©</span>
            <script>document.write(new Date().getFullYear());</script>
          </p>
          <div class="d-flex align-items-center gap-4">
            <a
              href="javascript:void(0);"
              class="fs-11 fw-semibold text-uppercase"
            >
              Help
            </a>
            <a
              href="javascript:void(0);"
              class="fs-11 fw-semibold text-uppercase"
            >
              Terms
            </a>
            <a
              href="javascript:void(0);"
              class="fs-11 fw-semibold text-uppercase"
            >
              Privacy
            </a>
          </div>
        </footer>
        {importFileType && (
          <UploadPopUp
            killImportPannel={killImportPannel}
            setFilter={setFilter}
            tags={tags}
          />
        )}
      </main>
    </div>
  );
}

export default Email;
