import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./header.css";
function Header() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  useEffect(() => {
    var user_json = localStorage.getItem("orkit_user");
    var parsed = JSON.parse(user_json);
    setUser(parsed);
  }, []);

  function logoutUser() {
    localStorage.removeItem("orkit_token");
    localStorage.removeItem("orkit_user");
    navigate("/Login");
  }
  return (
    <header class="nxl-header">
      <div class="header-wrapper">
        {user && !user.is_profile_complete && (
          <button
            className="btn btn-warning m-auto"
            onClick={() => {
              navigate("/Profile");
            }}
          >
            Your Profile Is Incomplete, To Have the full orkit experience ,
            Please add profile informations
          </button>
        )}
        <div class="header-right ms-auto">
          <ul class="list-unstyled">
            <li class="nxl-h-item d-none d-sm-flex"></li>
            <li class="dropdown nxl-h-item">
              <a
                href="javascript:void(0);"
                data-bs-toggle="dropdown"
                role="button"
                data-bs-auto-close="outside"
              >
                <i id="headerUserIcoinb" class="feather-user"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end nxl-h-dropdown nxl-user-dropdown">
                <div class="dropdown-header">
                  <div class="d-flex align-items-center">
                    <i id="headerUserDropdownIcoinb" class="feather-user"></i>

                    <div>
                      <h6 class="text-dark mb-0">
                        {user ? user.first_name + " " + user.last_name : "aa"}
                        <span class="badge bg-soft-success text-success ms-1">
                          PRO
                        </span>
                      </h6>
                      <span class="fs-12 fw-medium text-muted">
                        {user ? user.email : "aaa"}
                      </span>
                    </div>
                  </div>
                </div>

                <a
                  class="dropdown-item"
                  onClick={() => {
                    navigate("/profile");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i class="feather-user"></i>
                  <span>Profile</span>
                </a>
                <a class="dropdown-item">
                  <i class="feather-hexagon"></i>
                  <span>{user && user.leads_id.length + " Total Leads"}</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" onClick={logoutUser}>
                  <i class="feather-log-out"></i>
                  <span>Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
