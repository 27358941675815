import "./uploadpopup.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import "@yaireo/tagify/dist/tagify.css"; // Importing Tagify CSS
import DemoTable from "./DemoTable";
import axios from "axios";
import ComingSoon from "./comingSoon";
import { useNavigate } from "react-router-dom";
function UploadPopUp(props) {
  const killImportPannel = props.killImportPannel;
  const setFilter = props.setFilter;

  const navigate = useNavigate();
  const [menuDisplayed, setMenuDisplayed] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [uplaodFile, setUploadFile] = useState(null);
  const [fileDemo, setFileDemo] = useState(null);
  const [Error, setError] = useState(null);
  const [laoding, setLoading] = useState(false);

  const [tags, setTags] = useState(props.tags || []);
  function get_token() {
    var token = localStorage.getItem("orkit_token");
    if (!token) {
      navigate("/Login");
      return;
    }
    token = token.slice(1, -1);
    if (!token) {
      navigate("/Login");
      return;
    }
    return token;
  }
  async function save_file_backend() {
    setLoading(true);
    const token = get_token();

    const headers = {
      Authorization: "Bearer " + token,
    };

    const formData = new FormData();
    formData.append("file", uplaodFile);
    formData.append("tags", selectedTags);

    return await axios
      .post(process.env.REACT_APP_API_URL + "upload_csv", formData, { headers })
      .then((response) => {
        if (response.status == 201) {
          setFilter((prevState) => ({
            ...prevState,
            page: 0,
          }));
          killImportPannel();
        }
      })
      .catch((error) => {
        setError("Unable to Save, Please Try again Later");
        setLoading(false);
        return;
      });
  }

  const handleCsvUploadFile = (file) => {
    if (file.type !== "text/csv") {
      setError("Only Accepts Csv Files");
      return;
    }

    setUploadFile(file);
  };

  const saveFile = () => {
    if (selectedTags.length === 0) {
      setError("Title Missing");
      return;
    }
    if (!uplaodFile) {
      setError("File Is Missing");
      return;
    }
    save_file_backend();
  };

  function capitalizeFirstLetter(str) {
    if (str.length === 0) return str; // Check for an empty string

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <div
      className="ImportWrapper"
      onClick={() => {
        if (menuDisplayed) {
          setMenuDisplayed(false);
        }
      }}
    >
      <section class="step-body mt-4">
        <div>
          <div
            class="mb-5 d-flex justify-content-between"
            onClick={() => {
              setMenuDisplayed(false);
            }}
          >
            <h2 class="fs-16 fw-bold">Attachement files</h2>
            <a
              class="avatar-text avatar-md bg-soft-danger close-icon"
              onClick={killImportPannel}
            >
              <i class="feather-x text-danger"></i>
            </a>
          </div>

          <div class="modal-body p-0">
            <div class="position-relative border-bottom">
              <div class="px-2 d-flex align-items-center">
                <div class="p-0 w-100">
                  <tags
                    class="tagify form-control border-0 text-dark"
                    tabindex="-1"
                    aria-expanded="false"
                  >
                    {selectedTags.map((tag, i) => {
                      return (
                        <tag
                          contenteditable="false"
                          spellcheck="false"
                          tabindex="-1"
                          class="tagify__tag "
                        >
                          <x
                            title=""
                            class="tagify__tag__removeBtn"
                            role="button"
                            aria-label="remove tag"
                            onClick={() => {
                              const newArray = [...selectedTags];
                              newArray.splice(i, 1);
                              setSelectedTags(newArray);
                            }}
                          ></x>
                          <div>
                            <span class="tagify__tag-text">
                              {capitalizeFirstLetter(tag)}
                            </span>
                          </div>
                        </tag>
                      );
                    })}
                    <span
                      tabindex="0"
                      data-placeholder="Title"
                      aria-placeholder="Title"
                      role="textbox"
                      aria-autocomplete="both"
                      aria-multiline="false"
                    >
                      <input
                        class="form-control border-0 text-dark"
                        placeholder="Title"
                        onClick={() => {
                          setMenuDisplayed(true);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (
                              selectedTags.includes(
                                e.target.value.toLowerCase()
                              )
                            ) {
                              return;
                            }
                            setSelectedTags([
                              ...selectedTags,
                              e.target.value.toLowerCase(),
                            ]);
                            setMenuDisplayed(false);
                            e.target.value = "";
                          }
                        }}
                      />
                    </span>
                  </tags>
                </div>
              </div>
            </div>
            {menuDisplayed && (
              <div
                class="border-bottom mail-cc-bcc-fields"
                id="ccbccToggleModalFileds"
              >
                <div
                  class="tagify__dropdown users-list"
                  role="listbox"
                  aria-labelledby="dropdown"
                  placement="bottom"
                  position="all"
                  display="none"
                  id="dropdownList"
                  style={{ width: "100%", top: "303px;" }}
                >
                  <div
                    data-selector="tagify-dropdown-wrapper"
                    class="tagify__dropdown__wrapper"
                  >
                    {tags.map((t, i) => {
                      return (
                        <div
                          class="tagify__dropdown__item "
                          tabindex="0"
                          role="option"
                          tagifysuggestionidx="1"
                          style={{ opacity: "1" }}
                          onClick={() => {
                            if (selectedTags.includes(t.toLowerCase())) {
                              return;
                            }
                            setSelectedTags([...selectedTags, t.toLowerCase()]);
                          }}
                        >
                          <div class="tagify__dropdown__item__avatar-wrap">
                            {" "}
                          </div>{" "}
                          <strong>{t}</strong>{" "}
                          <span class="text-gray-500"></span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div>
            <label
              for="choose-file"
              class="custom-file-upload"
              id="choose-file-label"
            >
              <i
                class="feather-paperclip"
                style={{ "margin-right": "1vw" }}
              ></i>
              {uplaodFile ? uplaodFile.name : "Upload Document"}
            </label>
            <input
              accept=".csv"
              name="uploadDocument"
              type="file"
              id="choose-file"
              style={{ display: "none" }}
              onChange={(e) => {
                if (!e.target.files) {
                  return;
                }
                const file = e.target.files[0];
                handleCsvUploadFile(file);
              }}
            />
            {Error ? (
              <div
                class="alert alert-danger mt-2vh mb-2vh"
                role="alert"
                style={{ width: "20vw;" }}
              >
                {Error}
              </div>
            ) : (
              ""
            )}
          </div>
          {uplaodFile ? (
            <DemoTable
              file={uplaodFile}
              save_file={saveFile}
              error={Error}
              loading={laoding}
            />
          ) : (
            <ComingSoon />
          )}
        </div>
      </section>
    </div>
  );
}

export default UploadPopUp;
