import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

const TextEditor = (props) => {
  const initialContent = props.content.email_content;
  const setDiplaeydEmailContent = props.setDiplaeydEmailContent;
  const editorRef = useRef(null);
  const quillInstance = useRef(null);

  useEffect(() => {
    if (editorRef.current && !quillInstance.current) {
      quillInstance.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ font: [] }],
            ["clean"], // remove formatting
          ],
        },
      });

      quillInstance.current.on("text-change", () => {
        const content = quillInstance.current.getText(); // or getText() for plain text
        setDiplaeydEmailContent(content);
      });
    }
    quillInstance.current.setText(initialContent);
  }, [initialContent]);

  return <div ref={editorRef}> </div>;
};

export default TextEditor;
